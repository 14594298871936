.contact-page {
  text-align: center;
  padding: 50px;
}
@media (max-width: 1250px) {
  .contact-page {
    text-align: center;
    padding: 25px;
  }
}
.contact-page__title {
  font-size: 3rem;
  color: #333;
  margin-bottom: 40px;
}

.contact-page__form {
  max-width: 600px;
  margin: 0 auto;
}

.contact-page__form-row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.contact-page__form-col {
  flex: 1;
  margin-right: 10px;
}

.contact-page__form-col:last-child {
  margin-right: 0;
}

.contact-page__form-col label {
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 8px;
  text-align: left;
}

.contact-page__form-col input,
.contact-page__form-col textarea {
  width: 90%;
  padding: 10px;
  font-size: 1rem;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.contact-page__form-col textarea {
  height: 100px;
  resize: vertical;
}

.contact-page__form-row--full {
  flex-direction: column;
}

.contact-page__form-row--full .contact-page__form-col {
  flex-basis: 100%;
  margin-right: 0;
}

.contact-page__submit-button {
  display: inline-block;
  padding: 12px 24px;
  background-color: #FE6500;
  color: #fff;
  font-size: 1.2rem;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  border: none;
  cursor: pointer;
}

.contact-page__submit-button:hover {
  background-color: black;
}

.contact-page__submit-button:active {
  transform: translateY(1px);
}

.contact-page__message {
  margin: 20px 0;
  padding: 10px;
  font-size: 1.2rem;
  text-align: center;
}

.contact-page__message.success {
  color: green;
  background-color: #d4edda;
  max-width: 580px;
  width: 65vw;
  margin:auto;
}

.contact-page__message.error {
  color: red;
  background-color: #f8d7da;
  max-width: 580px;
  width: 65vw;
  margin:auto;
}

@media (max-width: 1250px) {
  .contact-page__form-col {
    flex-basis: 100%;
    margin-right: 0;
  }
}
