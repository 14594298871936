.testimonial-page {
  text-align: center;
  padding: 50px;
}

@media (max-width: 1250px) {
  .testimonial-page {
    text-align: center;
    padding: 25px;
  }
}

.testimonial-page__title {
  font-size: 3rem;
  color: #333;
  margin-bottom: 20px;
}

.testimonial-page__description {
  font-size: 1.5rem;
  color: #777;
  margin-bottom: 20px;
}

.testimonial-page__gallery {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.testimonial-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* width: 100%; */
  margin-bottom: 20px;
}

.testimonial-item__label {
  font-weight: bold;
  margin-bottom: 10px;
}

.testimonial-item__images-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.testimonial-item__image {
  width: 48%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 10px;
}

.testimonial-page__slider {
  margin: 0 auto;
  max-width: 900px;
  padding: 20px;
}

.testimonial-page__rating {
  font-size: x-large;
  margin: 0px;
  padding: 0px;
}

.testimonial-page__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #ffffff69;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  height: 200px;
  text-align: center;
  transition: box-shadow 0.3s ease;
  padding: 30px;
  width: 75% !important;
}

.testimonial-page__item:hover {
  box-shadow: 0 4px 8px #FE6500;
}

.testimonial-page__quote {
  font-size: medium;
  margin-bottom: 10px;
}

.testimonial-page__name {
  font-weight: bold;
}

.slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgb(166, 165, 165) !important;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000000;
  font-size: 24px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.slick-arrow:hover {
  background-color: #FE6500 !important;
}

.slick-prev {
  left: 20px;
}

.slick-next {
  right: 20px;
}