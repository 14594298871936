.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: white;
}

html {
  background-color: black;
}

.header {
  background-color: black;
  padding: 20px;
  display: flex;
  margin: 0px;
  align-items: center;
  position: fixed;
  width: 100%;
  padding-left:80px;
  z-index:1; /*Add this*/
  top: 0;
  overflow-x: hidden;
  left: 0;
}

.navbar__logo-container {
  margin-right: 10px;
}

.navbar__logo {
  height: 100px;
}

.navbar__list {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.navbar__item {
  margin-right: 20px;
}

.navbar__link {
  color: #fff;
  text-decoration: none;
  font-size: 1.2rem;
  transition: color 0.3s ease-in-out;
}

.navbar__link:hover {
  color: #FE6500;
}
.main {
  flex-grow: 1;
  padding: 20px;
  background-color: white;
  padding-top: 120px;
  max-width: 1450px;
  left: 50%;
  position: relative;
  transform: translateX(-50%);
}

.footer {
  background-color: black;
  padding: 20px;
  color: #fff;
  text-align: center;
  font-size: 0.8rem;
}

.hamburger {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  width: 30px;
  height: 21px;
}


@media (max-width: 1250px) {
  .header {
    justify-content: flex-start;
  }

  .navbar {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    padding: 20px;
    text-align: center;
  }

  .navbar.open {
    display: block;
  }

  .navbar__list {
    flex-direction: column;
  }

  .navbar__item {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .navbar__link {
    font-size: 1.5rem;
  }

  .hamburger {
    display: flex;
  }
  .header{
    padding-left: 20px;
  }
  
}

@media (min-width: 1250px) {
  .mobile{display: none;
  }
}
