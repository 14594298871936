.landing-page {
  text-align: center;
  padding: 50px;
}
@media (max-width: 1250px) {
  .landing-page {
    text-align: center;
    padding: 25px;
  }
}

.landing-page__header {
  margin-bottom: 40px;
}

.landing-page__title {
  font-size: 3rem;
  color: #333;
  margin-bottom: 10px;
}

.landing-page__subtitle {
  font-size: 1.5rem;
  color: #777;
}

.landing-page__section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 80px;
}

.landing-page__image-container {
  width: 100%;
  max-width: 600px;
  margin-bottom: 30px;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.landing-page__image {
  width: 100%;
  max-height: 50vh;
  object-fit: cover;
}

.landing-page__text-container {
  max-width: 600px;
  margin-bottom: 40px;
  padding-left: 20px;
}

.landing-page__section-title {
  font-size: 2rem;
  color: #333;
  margin-bottom: 20px;
}

.landing-page__section-description {
  font-size: 1.2rem;
  color: #555;
  margin-bottom: 20px;
}

.landing-page__cta-button {
  display: inline-block;
  padding: 12px 24px;
  background-color: #FE6500;
  color: #fff;
  font-size: 1.2rem;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.landing-page__cta-button:hover {
  background-color: black;
}

.landing-page__features-list {
  list-style: none;
  padding: 0;
  text-align: left;
  margin-top: 20px;
}

.landing-page__features-list li {
  font-size: 1.1rem;
  color: #555;
  margin-bottom: 10px;
}

@media (min-width: 1250px) {
  .landing-page {
    padding: 50px 100px 50px 100px;
  }

  .landing-page__section {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .landing-page__text-container {
    margin-bottom: 0;
  }

  .landing-page__image-container {
    margin-bottom: 0;
  }
}
